$secondaryColor: #a0a0a0;
$primaryColor: #121212;

.product-item-row {
  display: flex;
  margin-bottom: 64px;
  width: 100%;

  .product-item-row__image {
    height: 125px;
    width: 125px;
    margin-right: 12px;
    img {
      width: 100%;
      height: 100%;
      object-fit:contain;
    }
  }

  .product-item-meta {
    flex: 1;

    .product-item-title {
      font-size: 20px;
      line-height: 22px;
      color: $primaryColor;
      margin-bottom: 12px;
    }
    .product-item-description {
      font-size: 14px;
      line-height: 20px;
      color: $secondaryColor;
    }
  }
    .product-item-quantity-container {
      display: flex;
      align-items: center;
      padding: 0 20px;
      justify-content: center;

      .product-item-quantity-input {
        margin-right: 20px;
      }
      .product-item-quantity-control {
      }
    }
  
}
