.recommended-product {
  display: flex;
  padding: 25px;
  box-shadow: none;
  border: 1px solid #ededed;
  position: relative;

  &:hover {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
  }

  &:not(:last-child) {
    margin-bottom: 18px;
  }

  &.selected {
    background: #edf9f0;
    border: 2px solid #3ab85d;
  }

  .recommended-product-image {
    width: 128px;
    height: 128px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .selection-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 5px;
    left: 5px;
    background: none;
    border: 0;
    outline: 0;
    padding: 0;

    svg {
      width: 20px;
      height: 20px;
    }
  }
  .recommended-product-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    .recommended-product-category {
      font-size: 14px;
      font-weight: bold;
      line-height: 18px;
      color: #d0d0d0;
    }
    .recommended-product-title {
      color: #121212;
      font-size: 20px;
      line-height: 22px;
      margin-bottom: 9px;
    }
    .recommended-product-description {
      color: #a0a0a0;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 27px;
    }
    .recommended-product-controls {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .recommended-product-datasheet {
        color: #42b4e6;
        font-size: 12px;
        line-height: 16px;
        font-weight: bold;
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
          margin-right: 8px;
          * {
            fill: #42b4e6;
          }
        }
      }

      .recommended-product-quantity {
      }
    }
  }
}
