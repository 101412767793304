#react-burger-menu-btn,
#react-burger-cross-btn {
  outline: none !important;
  -webkit-tap-highlight-color: transparent !important;
}

#react-burger-menu-btn {
  width: 70px !important;
}

.bm-menu {
  box-shadow: -2px 3px 15px rgba(0, 0, 0, 0.16);
}

.bm-menu-wrap {
  left: 0;
  top: 60px;
  transition-duration: 300ms !important;

  @media screen and (max-width: 600px) {
    top: 100px;    
  }
}

.bm-overlay {
  top: 60px;
  background: rgba(0, 0, 0, 0.36) !important;

  @media screen and (max-width: 600px) {
    top: 100px;    
  }
}

.bm-cross-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.SidebarMenu {
  height: inherit;
  overflow-y: auto;

  .item {
    display: flex;
    flex-direction: column;
    appearance: none;
    border: none;
    background: transparent;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px 0;
    cursor: pointer;
    outline: none;
    box-sizing: 0 0 2px black;

    img {
      max-height: 150px;
      width: 100%;
      object-fit: cover;
    }

    .item-text {
      // font-size: 13px;
      font-size: 16px;
      font-weight: 600;
      padding-top: 10px;
    }

    &.active {
      background-color: #fff;
    }
  }


// .SidebarMenu {
//   height: 100%;
//   padding: 0 0 20px;
//   background: #fff;
//   color: #121212;
//   outline: none !important;
//   display: flex !important;
//   flex-direction: column;



  &-footer {
    margin-top: auto;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }

  &-version {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
}