$green: #3ab85d;
.cart-container {
  height: calc(100% - 80px);
  margin-top: 60px;

  .cart-header {
    margin-top: 100px;
    background: #ecf8ef;
    padding: 20px 50px;
    display: flex;
    .cart-header-title {
      color: $green;
      width: 100%;
      font-size: 22px;
      line-height: 30px;
      font-weight: 500;
    }

    .right {
      display: flex;
      button {
        background: transparent;
        border: 0;
        width: 200px;
        cursor: pointer;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          margin-right: 12px;
          * {
            fill: #42b4e6;
          }
        }
      }
    }
  }

  .cart-items-empty {
    padding: 50px;
  }

  .cart-items {
    padding: 50px;
    height: calc(100% - 310px);
    overflow-y: auto;

    .cart-row-image-cell {
      max-width: 125px;
      width: 100%;
      width: 100%;
      height: auto;
      object-fit: contain;
    }

    .remove-item-from-cart {
      background: #fff;
      border: 0;
      outline: none;
      width: 30px;
      height: 30px;
      cursor: pointer;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .cart-footer {
    margin: 50px;
    border: 1px solid #d8d8d8;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #f5f5f5;
    padding: 8px;
    display: flex;
    align-items: center;

    .cart-footer-title {
      flex: 1;
      padding-left: 40px;
      font-size: 32px;
      line-height: 40px;
      font-weight: bold;
    }
    .cart-footer-right {
      display: flex;
      align-items: center;
      .cart-footer-quantity {
        font-size: 24px;
        line-height: 29px;
        font-weight: bold;
        margin-right: 100px;
        color: #393e3c;
      }

      .cart-footer-total {
        font-size: 24px;
        line-height: 29px;
        font-weight: bold;
        margin-right: 100px;
        color: #393e3c;
      }
    }
  }
  .cart-controls {
    padding: 0 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      margin-bottom: 30px !important;
      &.active {
      }
    }
  }
}
