.quiz-question {
  padding: 10px 40px;
  width: 100%;
  float: left;
  &:not(:last-child) {
    border-bottom: 1px solid #ededed;
  }

  .quiz-question-title {
    font-size: 22px;
    line-height: 27px;
    color: #393e3c;
    margin-bottom: 20px;
  }

  .quiz-question-option-container {
    .quiz-question-option-item {
      float: left;
      margin-right: 16px;
      margin-bottom: 16px;

      label {
        display: inline-block;
        border-radius: 30px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        padding: 6px 25px;
        cursor: pointer;

        text-align: center;
        border: 1px solid #42b4e6;
        &:hover {
          border: 1px solid #7c7c7c;
          background: #7c7c7c;
          color: #fff;
        }
      }

      input {
        display: none;
        &:checked ~ label {
          border: 1px solid #7c7c7c;
          background: #7c7c7c;
          color: #fff;
        }
      }
    }
  }
}
