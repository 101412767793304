.item-quantity {
  display: inline-flex;
  align-items: center;

  .item-quantity-label {
    color: #a0a0a0;
    margin-right: 10px;
  }
  .item-quantity-form {
    border: 2px solid #ededed;
    background-color: #fff;
    display: flex;
    align-items: center;
    .item-quantity-button {
      background: #fff;
      border: #fff;
      color: #121212;
      font-size: 18px;
      line-height: 24px;
      cursor: pointer;
    }

    .item-quantity-value {
      color: #121212;
      font-size: 18px;
      line-height: 24px;
      padding: 0 10px;
    }
  }
}
