$green: #3ab85d;
.ConfiguratorDropdown {
  display: inline-flex;
  flex-direction: column;
  position: absolute;
  // min-width: 200px;

  z-index: 30;

  &-active {
    z-index: 31;
  }
  &-hovered {
    .title,.ConfiguratorDropdown-body {

      transform: scale(110%);
      transform-origin: top left;
    }
  }

  .ConfiguratorDropdown__Icon-Add {
    border-radius: 5px;
    cursor: pointer;
    background-color: rgb(66, 180, 230);;
    width: 30px;
    border:0;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 25px;
      height: 25px;
    }

    &__Active {
      background-color: $green;
    }
  }

  &-expanded {
    border-radius: 10px 10px 0 0;
    // border-bottom: 1px solid #c3c3c3;
    z-index: 100;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .title {
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      border-bottom: 1px solid gray;
    }
  }

  .ConfiguratorDropdown__Dot {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-main {
    display: flex;
    align-items: center;
    padding: 3px 30px 3px 3px;
    text-align: left;

    .btn {
      cursor: pointer;
      appearance: none;
      border: none;
      background-color: transparent;
      outline: none;
    }

    .title {
      font-weight: 600;
      height: 36px;
      font-size: 18px;
      background-color: #fff;
      display: flex;
      width: 200px;
      align-items: center;
      justify-content: flex-start;
      box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      cursor: pointer;

      button:not(.ConfiguratorDropdown__Icon-Add) {
        background: none;
        border: 0;
        cursor: pointer;
        outline: none;
      }
    }
  }

  &-body {
    position: absolute;
    top: 40px;
    background-color: #fff;
    right: 0;
    left: 51px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 14px 0px 14px;
    font-size: 14px;
    width: 200px;
    text-align: left;

    @-moz-document url-prefix() {
      left: 47px;
    }

    button {
      width: 100%;
      text-align: left;
      background: #fff;
      border: 0;
      cursor: pointer;

      padding: 10px 0;
      padding-left: 10px;
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
        width: 20px;
        height: 20px;
      }
      span {
        flex: 1;
      }

      &:hover {
        background: darken(#fff, 10%);
      }
    }
  }
}
