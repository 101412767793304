.single-category-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow-y: auto;

    .single-category-modal-image {
        width: 250px;
        height: 250px;
        margin-bottom: 60px;
        img {
            width: 100%;
            object-fit: contain;
        }
    }

    .single-category-modal-description {
        margin: 10px 0;
    }

    .single-category-modal-controls {
    }
}
