$green: #3ab85d;

html {
    height: 100%;
    body {
        height: 100%;
        #root {
            height: 100%;
            .App {
                height: 100%;
                .AppContainer {
                    height: 100%;
                    overflow: hidden;
                }
            }
        }
    }
}
.Toastify__toast-container {
    z-index: 9999999999991 !important;
    .Toastify__toast--success {
        background: $green !important;
    }
}
