$borderColor: #ededed;

.SlidePane-overlay {
  z-index: 99;
  box-shadow: -2px 3px 15px rgba(0, 0, 0, 0.16);
  top: 60px;

  @media screen and (max-width: 600px) {
    top: 100px;
  }
  &.initial {
    top: 0px;
    pointer-events: none;

    .sidebar {
      .slide-pane__content{
        height: 100%;
      }
    }

    .SlidePane {
      pointer-events: all;
    }

    .slide-pane__close {
      display: none;
    }
  }

  &.overlay-after-open {
    background-color: rgba(0, 0, 0, 0.45);
  }
}

.SlidePane {
  position: relative;
  max-width: 1200px;

  .SlidePane-footer {
    height: 60px;
  }

  .slide-pane__close {
    width: min-content;
    margin-left: 12px;
    .CloseButton {
      .svg-container {
        text-align: center;
        min-width: fit-content;
        svg {
          height: auto;
        }
        span {
          font-weight: 600;
        }
      }
    }
  }

  .slide-pane__header {
    background: #fff;
    border-bottom-color: $borderColor;
    flex: 0 0 60px;
    height: fit-content;
    .slide-pane__title-wrapper {
      margin-left: 10px;
      .slide-pane__subtitle {
        margin-top: 8px;
      }
    }
  }

  &.hide-arrow-container {
    .slide-pane__header {
      // display: none;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100% !important;
  }

  &-inner {
    padding-top: 10px;
  }

  &-body {
  }

  &-dismiss {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    appearance: none;
    border: none;
    background-color: transparent;
    outline: none;
  }

  &.sidebar {
    height: 100vh;

    .slide-pane__content {
      padding: 0;
      height: calc(100% - 30px);
      
      overflow: hidden;
      .SlidePane-body {
        height: inherit;
      }
    }

    .SlidePane-inner {
      padding: 0;
    }
  }
}
