$green: #3ab85d;

.Header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  background-color: #fff;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;

  .Header__Content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    .left {
      align-self: flex-start;
      display: flex;
      align-items: center;
      height: 100%;

      .menu-btn {
        appearance: none;
        border: none;
        background-color: transparent;
        outline: none;
        cursor: pointer;
        padding: 15px 20px 10px;

        display: inline-flex;
        flex-direction: column;
        align-items: center;
        svg {
          &.active {
            border: 2px solid $green;
            border-radius: 4px;
            g{
              path{
                fill: $green;
              }
            }
          }
        }
      }

      .page-logo {
        margin-left: 40px;
        font-weight: 600;
        font-size: 18px;

        img {
          max-height: 36px;
        }
      }
    }

    .center {
      text-align: center;
      font-weight: 600;
      .page-title {
        font-size: 20px;
      }
    }

    .right {
      padding-right: 24px;
      min-width: 260px;
      display: flex;
      align-items: center;

      @media screen and (max-width: 600px) {
        min-width: 60px;
      }

      .cart-navmenu {
        display: flex;
        align-items: center;
        text-decoration: none;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        color: #000000;
        padding: 10px;
        border-radius: 10px;
        margin-right: 20px;

        &.active {
          background: #ecf8ef;
        }
        svg {
          font-size: 18px;
          margin-right: 8px;
          width: 20px;
          height: 20px;
          * {
            fill: #3ab85d;
          }
        }

        span.cart-count {
          margin-left: 10px;
          border: 2px solid $green;
          text-align: center;
          border-radius: 100%;
          width: 40px;
          font-weight: bold;
          height: 40px;
          color: $green;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    @media screen and (max-width: 600px) {
      flex-wrap: wrap;
      min-height: 100px;
      height: unset;
      padding-top: 10px;

      .left {
        width: 100%;
      }

      .center,
      .right {
        padding: 10px 0;
      }
    }
  }
}
