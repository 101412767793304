.ConfirmationDialog-btn  {
    display: inline-block;
    border-radius: 30px;
    font-size: 18px;
    line-height: 25px;
    font-weight: 600;
    padding-left: 30px;
    padding-right: 35px;
    padding-top: 12px;
    padding-bottom: 12px;
    cursor: pointer;

    background: #fff;
    text-align: center;
    border: 1px solid #42b4e6;
    &:hover {
      border: 1px solid #7c7c7c;
      background: #7c7c7c;
      color: #fff;
    }
    &.cancel {

    }
    &.success {

    }
}