
.Modal {
  position: relative;
  background-color: #fff;
  outline: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border: none;
  min-width: 500px;
  margin-top: 100px;

  @media screen and (max-width: 600px) {
    min-width: unset;
  }

  &-size-sm {
    width: 30vw;
    max-width: 448px;
  }

  &-size-md {
    width: 40vw;
    max-width: 640px;
  }

  &-size-lg {
    width: 65vw;
    max-width: 896px;
  }

  &-size-xl {
    width: 90vw;
    max-width: 1280px;
  }

  &-size-full {
    width: 100vw;
    height: 100vh;
    margin-top: 0;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }

  .Modal-body {
    padding: 20px 32px;
  }

  .Modal-dismiss {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    cursor: pointer;
    outline: none;
    background-color: transparent;

    &:active {
      outline: none;
    }
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}