$mobileBreakpoint: 991px;

::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

::-webkit-scrollbar-button {
  display: none;
}

@-moz-document url-prefix() {
  * {
    scrollbar-width: thin;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .slide-pane__overlay.SlidePane-overlay.overlay-after-open {
    top: 0;
    z-index: 9999999;
    .slide-pane__header {
      height: unset;
      // padding-bottom: 15px;
    }
    .slide-pane__content {
      padding: 10px;
      .SlidePane-body {
        padding-bottom: 20px;
        //Disabled temp. because duplicate one appeared on resize
        // overflow-y: scroll;

        .product-item-container {
          height: auto;
        }
        .single-category-modal-content {
          .single-category-modal-image {
            width: 200px;
            height: 200px;
          }
        }

        .quiz-container {
          display: block;
          overflow-y: scroll;
          .quiz-container-questions {
            .quiz-question {
              width: 100%;
              padding: 10px;
            }
          }
          .quiz-container-results {
            border-top: 1px solid lightgray;
            padding-top: 20px;
            .recommended-product-container {
              padding: 10px;

              .recommended-product {
                flex-direction: column;
              }
            }
          }
        }
      }
    }
  }

  .Header {
    .center {
      width: 100%;
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      .cart-navmenu {
        margin-right: 0;
      }
    }
  }

  .MachineContainer {
    padding-top: 0;
    margin-top: 0;

    .ConfiguratorDropdown {
      min-width: unset;
      .ConfiguratorDropdown-main {
        padding: 3px;

        button {
          margin-right: 0;
        }
        .title {
          display: none;
        }
      }

      &.ConfiguratorDropdown-expanded {
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        width: 100% !important;
        height: 100% !important;
        z-index: 200;

        .ConfiguratorDropdown-body {
          width: 100%;
          height: 100%;
          
          button {
            &:not(:last-child) {
              border-bottom: 1px solid lightgray;
            }
          }
        }
      }
    }
  }

  .cart-container {
    .cart-items {
      width: 100%;
      padding: 0 10px;
      overflow-y: scroll;
      height: calc(100% - 310px);
      .cart-table {
        box-sizing: border-box;
        width: 100%;
        margin: 0;
        display: block;
        thead {
          display: none;
        }

        tbody {
          width: 100%;
          display: block;

          tr {
            width: 100%;
            display: block;
            height: auto !important;
            td {
              display: block;
              width: 100%;
              border: none;
              padding: 5px 0;
            }
          }
        }
      }
    }

    // .cart-items::-webkit-scrollbar {
    //   background-color: #fff;
    //   width: 16px;
    // }

    // /* background of the scrollbar except button or resizer */
    // .cart-items::-webkit-scrollbar-track {
    //   background-color: #fff;
    // }

    // /* scrollbar itself */
    // .cart-items::-webkit-scrollbar-thumb {
    //   background-color: #babac0;
    //   border-radius: 16px;
    //   border: 4px solid #fff;
    // }

    // /* set button(top and bottom of the scrollbar) */
    // .cart-items::-webkit-scrollbar-button {
    //   display: none;
    // }

    // //FF
    // @-moz-document url-prefix() {
    //   .cart-items {
    //     scrollbar-width: thin;
    //   }
    // }

    .cart-footer {
      flex-direction: column;
      margin: 10px;
      padding: 0 10px;

      .cart-footer-title {
        width: 100%;
      }

      .cart-footer-right {
        width: 100%;
        & > div {
          width: 100%;
          text-align: center;
          margin: 0 !important;
          padding: 10px;
        }
      }
    }
  }
}
