.InitialView {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  width: 100%;
  height: calc(100vh - 60px);

  img {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .text {
    display: flex;
    align-items: center;
    text-align: center;
    z-index: 1000;
    color: #fff;
    font-size: 44px;
    font-weight: bold;
    margin-left: 150px;

    svg {
      margin-right: 20px;
    }
  }

  .dropdown {
    position: absolute;
    top: 200px;
    left: 200px;
  }
}