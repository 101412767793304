$green: #3ab85d;
$gray: #bbbbbb;

.checkmark-container {
    display: flex;
    min-width: 200px;
    max-width: 200px;
    justify-content: center;
    .checkmark-btn {
        transition: all 0.25s ease;
        text-align: center;
        min-width: 200px;
        max-width: 200px;
        &.clicked {
            max-width: 30px;
            min-width: 30px;
            min-height: 30px;
            max-height: 30px;
            border-color: $gray;
            color: white;
            border-width: 3px;
            font-size: 0;
            border-left-color: $green;
            padding: 0;
            animation: rotating 2s 0.25s linear infinite;
            border-radius: 100%;
            background: white;
            margin: 10px 60px 0;

            &:after {
                content: "";
                background: white;
            }
            &:hover {
                // color: $green;
                // background: white;
            }
        }

        &.validate {
            color: white;
            min-width: 200px;
            max-width: 200px;
            &:after {
                content: "\2713";
            }
        }
    }
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
