
.Button {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid transparent;
  border-radius: 3px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  display: inline-block;
  color: #fff;
  outline: 0;
  cursor: pointer;

  .Loader-spinner {
    margin-right: 10px;
    border-top-color: #fff;
    border-width: 2px;
  }

  &:disabled {
    opacity: 0.8;
    pointer-events: none;
  }

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;

    &:hover {
      opacity: 0.3;
    }
  }

  &-loading {
    display: flex;
    align-items: center;
  }

  &-size-sm {
    font-size: 14px;
    height: 24px;
    padding: 0 15px;

    .Loader-spinner {
      width: 7px;
      height: 7px;
    }
  }

  &-size-md {
    font-size: 16px;
    font-weight: 500;
    height: 40px;
    padding: 0 21px;
    border-width: 2px;

    .Loader-spinner {
      width: 10px;
      height: 10px;
    }
  }

  &-size-lg {
    font-size: 18px;
    font-weight: 500;
    height: 44px;
    padding: 0 35px;
    border-width: 2px;

    .Loader-spinner {
      width: 15px;
      height: 15px;
    }
  }

  &-theme-default {
    background: #42B4E6;
  }

  &-theme-success {
    background: #3AB85D;
  }

  &-theme-warning {
    background: #FDFE4E;
  }

  &-theme-error {
    background: #F45E5E;
  }
}
