.cart-table {
  width: 100%;
  border-radius: 10px;
  // border: 1px solid #d8d8d8;
  border-spacing: 0;
  -webkit-border-horizontal-spacing: 0px;
  -webkit-border-vertical-spacing: 0px;
  thead {
    tr {
      text-align: center;
      height: 75px;
      .columnheader {
        text-align: center;
      }
      th {
        border: 1px solid #d8d8d8;

        &:first-child {
          border-left: 0;
          border-top: 0;
          width: 40px;
        }
      }
    }
  }

  tbody {
    tr {
      text-align: center;
      height: 150px;
      td {
        &:first-child {
          width: 40px;
        }
      }
      &:not(.category) {
        td {
          &:first-child {
            border-left: 0;
            border-bottom: 0;
          }
        }
      }
      &.category {
        height: 40px;
        background: #d8d8d8;

        td {
          text-align: left;
          height: 40px;
          * {
            display: none;
          }
          &:nth-child(1) {
            padding-left: 15px;
            width: 20px !important;

            span {
              font-size: 16px;
              color: #121212;
              line-height: 22px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              height: 100%;
              width: 400px;
              svg {
                margin-right: 20px;
              }
            }
          }

          &:nth-child(5) {
            color: #d8d8d8;
          }
        }
      }
      td {
        border: 1px solid #d8d8d8;
      }
    }
  }
}
