.FillingAndCapping {
  margin-top: 60px;
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media screen and (max-width: 600px) {
    padding-top: 100px;
    height: 100vh;
  }

  .image-container {
    position: relative;
    img {
      width: 100%;
      height: 100%;
      // max-height: 100vh;
    }
  }
}
