$mobileBreakpoint: 991px;

.loading-container {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: $mobileBreakpoint) {
  .slide-pane.QuizContainer {
    .slide-pane__content {
      overflow-y: hidden;
      .SlidePane-body {
        height: 100%;
      }
      .quiz-container-questions {
        height: 100%;
        overflow-y: scroll;
      }
      .quiz-container-results {
        height: inherit;
        .recommended-product-container {
          padding: 0 50px;
          height: 100%;
          overflow: hidden;

          & > div:first-child {
            height: calc(100% - 50px);
            .recommended-product-items {
              overflow-y: auto;
              height: inherit;
            }
          }

          .recommended-product-footer {
            margin: 5px;
          }
        }
      }
    }
  }
}

.quiz-container {
  display: flex;
  height: 100%;
  overflow: hidden;

  .quiz-container-questions {
    flex: 1;
    border-right: 1px solid #d9d9d9;
    overflow-y: scroll;
  }

  .quiz-container-incomplete {
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      text-align: center;
      margin: 10px;
    }
  }
  .quiz-container-results {
    flex: 1;
    height: 100%;
    .recommended-product-container {
      padding: 0 50px;
      height: 100%;
      display: flex;
      flex-direction: column;
      .recommended-product-header {
        display: flex;
        padding: 12px 0;
        .recommended-product-title {
          flex: 1;
          font-size: 16px;
          font-weight: bold;
          line-height: 20px;
        }
        .recommended-product-controls {
          button {
            border: 0;
            background: #fff;
            color: #68c883;
            font-size: 12px;
            line-height: 16px;
            font-weight: bold;
            cursor: pointer;

            svg {
              margin-right: 8px;
              * {
                fill: #68c883;
              }
            }
          }
        }
      }
      .recommended-product-items {
        overflow-y: auto;
        flex: 1;
      }

      .recommended-product-footer {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        button {
        }
      }
    }
  }
}
